<template>
  <chart :chartData="chartData" :options="chartOptionsMerged"></chart>
</template>

<script>
  import chart from '@/components/charts/lib/VerticalBar.vue';

  export default {
    name: 'VerticalBarChart',
    props: {
      loading: {
        required: false
      },
      chartData: {
        required: false
      },
      chartOptions: {
        required: false,
        default: () => ({})
      },
      title: {
        required: false
      },
      xAxisLabel: {
        required: false
      },
      yAxisLabel: {
        required: false
      }
    },
    components: { chart },
    data: function () {
      return {
        defaultChartOptions: {
          title: {
            display: !!this.title,
            text: this.title
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true
          },
          legend: {
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: !!this.xAxisLabel,
                  labelString: this.xAxisLabel
                }
              }
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: !!this.yAxisLabel,
                  labelString: this.yAxisLabel
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      };
    },
    computed: {
      chartOptionsMerged() {
        return {
          ...this.defaultChartOptions,
          ...this.chartOptions
        };
      }
    }
  };
</script>

<!--
EXAMPLES

DATA
{
  datasets: [{
    label: 'Dataset 1',
    backgroundColor: "red",
    data: [1, 2, 3]
  }, {
    label: 'Dataset 2',
    backgroundColor: "blue",
    data: [2, 3, 4]
  }],

  labels: ['Point A', 'Point B', 'Point C']
}

OPTIONS
{
  title: {
    display: this.title ? true : false,
    text: this.title
  },
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 3,
    tooltips: {
    enabled: true
  },
  legend: {
    // onClick: function() { return; }
  },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: this.xAxisLabel ? true : false,
          labelString: this.xAxisLabel
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: this.yAxisLabel ? true : false,
          labelString: this.yAxisLabel
        },
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }
}
-->
