<template>
  <div id="graph">
    <transition-group name="fade">
      <div key="1" v-if="!this.countData || !totalEncounters">
        Seems there is no data that meets your criteria.
      </div>

      <div key="3" v-else>
        <div
          class="grid"
          v-responsive="{
            'grid-cols-1': (el) => el.width <= 700,
            'grid-cols-2': (el) => el.width > 700,
            'gap-16': (el) => el.width > 700,
            'grid-flow-row': (el) => el.width > 700
          }"
        >
          <div>
            <div
              v-if="
                this.countData &&
                this.countData.length > 0 &&
                this.patientStatusBoth
              "
            >
              <h3 :class="chartHeaderClasses">
                Patient Status Ratio <small class="fs-12">(Patients)</small>
              </h3>
              <status-chart
                class="status-profile-chart"
                :chartData="patientStatusDataStucture"
                :chartOptions="patientStatusChartOptions"
              ></status-chart>
            </div>
          </div>

          <!-- Don't show unless there a comparison to see -->
          <div v-if="genderData[0] > 0 && genderData[1] > 0">
            <h3 :class="chartHeaderClasses">
              Gender Ratio <small class="fs-12">(Patients)</small>
            </h3>
            <div class="grid grid-cols-2 items-start">
              <gender-chart
                class="gender-profile-chart"
                :key="chartgenderKey"
                :chartData="genderDataStructure"
                :chartOptions="genderRatioChartOptions"
              ></gender-chart>
              <gender-insights :genderData="genderData"></gender-insights>
            </div>
          </div>
          <!-- prettier-ignore -->
          <div
            v-if="
              ageRangeData &&
              this.countData &&
              this.countData.length > 0"
              class="col-span-full"
              >
            <h3 :class="chartHeaderClasses">Patients by Age Group and Gender</h3>
            <age-chart
              class="age-by-gender-profile-chart"
              :key="chartageKey"
              :chartData="ageDataStructure"
              :chartOptions="ageDataChartOptions"
            ></age-chart>
          </div>

          <!-- Getting a weighted age for Insight counts doesn't work with current data structure. Need to talk to Reece when he gets back. -->
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  import statusChart from '@/components/charts/HorizontalBar';
  import genderChart from '../charts/Pie';
  import ageChart from '../charts/VerticalBar';
  import genderInsights from '../popbuilder/GenderInsights_v2';

  import { formatNumber } from '@/js/utilities';

  export default {
    name: 'PatientsCountCharts',
    components: {
      statusChart,
      genderChart,
      ageChart,
      genderInsights
    },
    props: {
      ageRangeChoices: Array,
      genderChoices: Array,
      filterPatientStatus: Array,
      countData: Array,
      patientStatusCountsPatients: Object,
      genderData: Array,
      ageRangeData: Object,
      totalEncounters: Number
    },
    computed: {
      patientStatusIncludesInpatient() {
        return this.filterPatientStatus.includes('inpatient');
      },
      patientStatusIncludesOutpatient() {
        return this.filterPatientStatus.includes('outpatient');
      },
      patientStatusBoth() {
        return (
          this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsInpatient() {
        return (
          this.patientStatusIncludesInpatient &&
          !this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsOutpatient() {
        return (
          !this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      genderDataStructure() {
        return {
          datasets: [
            {
              weight: 0.2,
              data: this.genderData,
              backgroundColor: [
                this.crcaColors.dataVis5,
                this.crcaColors.dataVis14
              ],
              borderColor: this.crcaColors.bodyBackground,
              borderWidth: 2,
              label: 'Patients'
            }
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: ['Male', 'Female'],
          options: {
            cutoutPercentage: 80
          }
        };
      },
      patientStatusDataStucture() {
        const labels = [['Inpatients'], ['Outpatients']];

        const datasets = [
          {
            data: [
              this.patientStatusCountsPatients.inpatient,
              this.patientStatusCountsPatients.outpatient
            ],
            backgroundColor: [
              this.crcaColors.contrast400,
              this.crcaColors.contrast900
            ]
          }
        ];

        return {
          labels: labels,
          datasets: datasets
        };
      },
      filteredMaleAgeData() {
        return Object.values(this.ageRangeData.male).filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      filteredFemaleAgeData() {
        return Object.values(this.ageRangeData.female).filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      filteredAgeLables() {
        const labels = [
          'Unknown',
          '0-17',
          '18-24',
          '25-34',
          '35-44',
          '45-54',
          '55-64',
          '65-74',
          '75-84',
          '85+'
        ];

        return labels.filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      ageDataStructure() {
        let datasets = [];
        if (this.genderChoices.includes('1')) {
          datasets.push({
            label: 'Male',
            backgroundColor: this.crcaColors.dataVis5,
            data: this.filteredMaleAgeData
          });
        }
        if (this.genderChoices.includes('2')) {
          datasets.push({
            label: 'Female',
            backgroundColor: this.crcaColors.dataVis14,
            data: this.filteredFemaleAgeData
          });
        }
        return {
          datasets: datasets,
          labels: this.filteredAgeLables
        };
      }
      // Insights for IP/OP counts can't really work for patient data structure - Removing until I can touch base with Reece
    },
    data() {
      return {
        chartgenderKey: 1,
        chartageKey: 2,
        watchers: {
          unwatchPopData: null
        },
        chartHeaderClasses: 'rfs-24 rfs-m-2-t rfs-m-2-b',
        patientStatusChartOptions: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value) {
                    return formatNumber(value, { format: 'largeNumberAbbr' });
                  }
                }
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          },
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem) {
                return tooltipItem[0].yLabel.join(' ');
              },
              label: function (tooltipItem, data) {
                const val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                return formatNumber(val, { format: 'commas' });
              }
            }
          }
        },
        genderRatioChartOptions: {
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem, data, i) {
                return data.datasets[0].label;
              },
              label: function (tooltipItem, data) {
                const val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  formatNumber(val, { format: 'commas' })
                );
              }
            }
          }
        },
        ageDataChartOptions: {
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem, data) {
                return tooltipItem[0].label;
              },
              label: function (tooltipItem, data) {
                const val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  ': ' +
                  formatNumber(val, { format: 'commas' })
                );
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value) {
                    return formatNumber(value, { format: 'largeNumberAbbr' });
                  }
                }
              }
            ]
          }
        }
      };
    }
  };
</script>
