<template>
  <chart :chartData="chartData" :options="chartOptionsMerged"></chart>
</template>

<script>
  import chart from '@/components/charts/lib/Pie.vue';

  export default {
    name: 'PieChart',
    props: {
      loading: {
        required: false
      },
      chartData: {
        required: false
      },
      chartOptions: {
        required: false,
        default: () => ({})
      },
      title: {
        required: false
      }
    },
    components: { chart },
    data: function () {
      return {
        defaultChartOptions: {
          title: {
            display: !!this.title,
            text: this.title
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            callbacks: {
              title: function (tooltipItem, data) {
                return data.datasets[tooltipItem[0].datasetIndex].label;
              }
            },
            alignment: 'right',
            position: 'nearest'
          },
          legend: {
            display: false
          }
        }
      };
    },
    computed: {
      chartOptionsMerged() {
        return {
          ...this.defaultChartOptions,
          ...this.chartOptions
        };
      }
    }
  };
</script>

<!--
EXAMPLES

DATA
{
  datasets: [{
    data: [10, 20, 30]
  }],

  // These labels appear in the legend and in the tooltips when hovering different arcs
  labels: [
    'Red',
    'Yellow',
    'Blue'
  ]
}

OPTIONS
{
  title: {
    display: true,
    text: "My Data"
  },
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
    callbacks: {
      title: function(tooltipItem, data) {
        return data.datasets[tooltipItem[0].datasetIndex].label
      }
    },
  alignment: 'right',
  position: 'nearest',

  },
  legend: {
    display: false
  }
}
-->
