<template>
  <!-- really not the best way to code this, but works for now -->
  <table
    style="width: 100%; text-align: center"
    class="population-insights-table"
  >
    <tr>
      <td>
        <span
          data-gender-incite-1
          class="callout fw-300"
          :style="{ color: crcaColors.dataVis5 }"
        >
          <!-- prettier-ignore -->
          <animated-number
            :number="genderData[0] | formatNumber({ format: 'precision', places: 0 })"
            :numberFormat="{ format: 'largeNumberAbbr' }"
          ></animated-number
          >
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="calloutLabel" :style="{ color: crcaColors.dataVis5 }"
          >Male</span
        >
      </td>
    </tr>
    <tr>
      <td>
        <span
          data-gender-incite-2
          class="callout fw-300"
          :style="{ color: crcaColors.dataVis13 }"
          ><animated-number
            :number="
              genderData[1] | formatNumber({ format: 'precision', places: 0 })
            "
            :numberFormat="{ format: 'largeNumberAbbr' }"
          ></animated-number
        ></span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="calloutLabel" :style="{ color: crcaColors.dataVis13 }"
          >Female</span
        >
      </td>
    </tr>
  </table>
</template>
<script>
  import AnimatedNumber from '@/components/base/animated_number.vue';
  export default {
    name: 'PopBuilderBuildGenderInsights',
    props: ['visits', 'patients', 'genderData'],
    components: {
      AnimatedNumber
    }
  };
</script>
