var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"graph"}},[_c('transition-group',{attrs:{"name":"fade"}},[(!this.countData || !_vm.totalEncounters)?_c('div',{key:"1"},[_vm._v(" Seems there is no data that meets your criteria. ")]):_c('div',{key:"3"},[_c('div',{directives:[{name:"responsive",rawName:"v-responsive",value:({
          'grid-cols-1': function (el) { return el.width <= 700; },
          'grid-cols-2': function (el) { return el.width > 700; },
          'gap-16': function (el) { return el.width > 700; },
          'grid-flow-row': function (el) { return el.width > 700; }
        }),expression:"{\n          'grid-cols-1': (el) => el.width <= 700,\n          'grid-cols-2': (el) => el.width > 700,\n          'gap-16': (el) => el.width > 700,\n          'grid-flow-row': (el) => el.width > 700\n        }"}],staticClass:"grid"},[_c('div',[(
              this.countData &&
              this.countData.length > 0 &&
              this.patientStatusBoth
            )?_c('div',[_c('h3',{class:_vm.chartHeaderClasses},[_vm._v(" Patient Status Ratio "),_c('small',{staticClass:"fs-12"},[_vm._v("(Patients)")])]),_c('status-chart',{staticClass:"status-profile-chart",attrs:{"chartData":_vm.patientStatusDataStucture,"chartOptions":_vm.patientStatusChartOptions}})],1):_vm._e()]),(_vm.genderData[0] > 0 && _vm.genderData[1] > 0)?_c('div',[_c('h3',{class:_vm.chartHeaderClasses},[_vm._v(" Gender Ratio "),_c('small',{staticClass:"fs-12"},[_vm._v("(Patients)")])]),_c('div',{staticClass:"grid grid-cols-2 items-start"},[_c('gender-chart',{key:_vm.chartgenderKey,staticClass:"gender-profile-chart",attrs:{"chartData":_vm.genderDataStructure,"chartOptions":_vm.genderRatioChartOptions}}),_c('gender-insights',{attrs:{"genderData":_vm.genderData}})],1)]):_vm._e(),(
            _vm.ageRangeData &&
            this.countData &&
            this.countData.length > 0)?_c('div',{staticClass:"col-span-full"},[_c('h3',{class:_vm.chartHeaderClasses},[_vm._v("Patients by Age Group and Gender")]),_c('age-chart',{key:_vm.chartageKey,staticClass:"age-by-gender-profile-chart",attrs:{"chartData":_vm.ageDataStructure,"chartOptions":_vm.ageDataChartOptions}})],1):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }