<script>
  import Chart from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels'; // https://chartjs-plugin-datalabels.netlify.app
  import { Pie, mixins } from 'vue-chartjs';

  Chart.plugins.unregister(ChartDataLabels); // have to unregister if you only want to turn it on for selected charts
  const { reactiveProp } = mixins;

  export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted() {
      this.renderChart(this.chartData, this.options);
    }
  };
</script>
